#root {
    height: 100vh;
    color: '#f9f9f9';
    background-color: 'white';
}

body {
    margin: 0;
    background-color: 'white';
}
